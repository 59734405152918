import React, {FC} from 'react';
import {InventoryDataSection} from './InventoryDataSection';
import {CommsCheckResult} from '../../types/comms-check';
import {OtherDevicesLogDataSection} from './OtherDevicesLogDataSection';
import {InstantaneousDataSection} from './InstantaneousDataSection';

export interface CommsCheckSuccessResultProps {
  commsCheckData: CommsCheckResult
  deviceLogLength: number;
}

export const CommsCheckSuccessResult: FC<CommsCheckSuccessResultProps> = ({
                                                                            commsCheckData,
                                                                            deviceLogLength,
                                                                          }) => {
  return (
    <>
      <div className="informationPanel m-b-10" data-testid="info-panel-success">
        <div className="m-b-10">
          <span className="resultKey">Result: </span><span className="resultValue">In Comms</span>
        </div>
        {(deviceLogLength !== 0) &&
          <div className="informationRow" data-testid="action-success-section">
            <div className="informationHeading">Action To Take:</div>
            <div className="informationSectionText">Other Devices Found. Please Contact SST</div>
          </div>
        }
        <div className="informationHeading">Information:</div>
        <InventoryDataSection data={commsCheckData.xrinv.data}/>
        <InstantaneousDataSection xritm={commsCheckData.xritm}/>
        <OtherDevicesLogDataSection data={commsCheckData.xrdlg.data}/>
      </div>
    </>
  );
}
