import React, { FC } from 'react';
import { CommsCheckResult } from '../../types/comms-check';
import { InventoryDataSection } from './InventoryDataSection';
import { InstantaneousDataSection } from './InstantaneousDataSection';
import { OtherDevicesLogDataSection } from './OtherDevicesLogDataSection';

export interface CommsCheckFailResultProps {
  failScreenData: any;
  commsCheckData: CommsCheckResult;
}

export const CommsCheckFailResult: FC<CommsCheckFailResultProps> = ({failScreenData,
                                                                      commsCheckData,
                                                                    }) => {


  return (
    <div className="infoContainer" data-testid="info-panel-fail">
      <div className="informationPanel m-b-10">
        <div className="failInformationSection">
          <div className="m-b-10">
            <span className="resultKey">Result: </span><span className="resultValue">{failScreenData.result}</span>
          </div>
          <div className="informationHeading">{`${failScreenData.information}:`}</div>
          {
            failScreenData.xrinvText &&
            <div className={'informationRow'} data-testid="xrinv-text-fail">
              <div className="informationSectionText">{failScreenData.xrinvText}</div>
            </div>
          }
          {failScreenData.xritmText && <div className="informationRow" data-testid="xritm-text-fail">
            <div className="informationSectionText">{failScreenData.xritmText}</div>
          </div>}
          {failScreenData.xrdlgText && <div className={'informationRow'} data-testid="xrdlg-text-fail">
            <div className="informationSectionText">{failScreenData.xrdlgText}</div>
          </div>}
          <div className="informationRow" data-testid="action-fail-screen">
            <div className="informationHeading">Action To Take:</div>
            <div className="informationSectionText">{failScreenData.action}</div>
          </div>
          {!(failScreenData.xrinvText && failScreenData.xritmText && failScreenData.xrdlgText) &&
            <div className="informationHeading" data-testid="info-devices-fail">Information:</div>}
          {!failScreenData.xrinvText && <InventoryDataSection data={commsCheckData.xrinv.data} />}
          {!failScreenData.xritmText && <InstantaneousDataSection xritm={commsCheckData.xritm} />}
          {!failScreenData.xrdlgText && <OtherDevicesLogDataSection data={commsCheckData.xrdlg.data} />}
        </div>
      </div>
    </div>
  );
}
