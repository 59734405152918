import React, { Fragment } from 'react';
import { XrdlgData } from '../../types/comms-check';


export const OtherDevicesLogDataSection = ({ data }: { data: XrdlgData[] }) => {

  return <>
    {data.length ? <div className={'informationRow'} data-testid="info-device-log-section">
      <div className={'informationHeading'}>Other Devices Found:</div>
      {data.map(({ deviceId, lastCommunicatedTime }) => <Fragment key={deviceId}>
          <div className={'informationSectionText'}>{`GUID: ${deviceId}`}</div>
          <div className={'informationSectionText'}>{`LCT: ${lastCommunicatedTime}`}</div>
        </Fragment>,
      )}
    </div> : null}
  </>;
}
