import React, { FC, useEffect, useRef } from 'react';
import {
  CommsCheckResult,
  XrdlgData,
  XrdlgResponseData,
  XrinvData,
  XrinvResponseData,
  XritmResponseData,
} from '../../types/comms-check';
import {
  apiCall,
  buildGetXmlRequest,
  buildXrdlgRequest,
  buildXrinvRequest,
  buildXritmRequest,
  makeReadableTimeStamp,
} from '../util';
import commsCheckInitialData, {useCommsCheckContext} from '../../context/CommsCheckContext';
import { proxy500L1error, proxy500L2error } from '../comms-check/util';
import { WaitingForResponse } from './WaitingForResponse';
import { CommsCheckSuccessResult } from './CommsCheckSuccessResult';
import { CommsCheckFailResult } from './CommsCheckFailResult';
interface CommsCheckProps {
  mpxn: string;
  msn: string;
}

export const CommsCheck: FC<CommsCheckProps> = ({mpxn, msn}) => {
  const [statusScreen, setStatusScreen] = React.useState<'SUCCESS' | 'FAIL' | 'LOAD' | ''>('');
  const { commsCheckResultContext, setCommsCheckResultContext } = useCommsCheckContext();

  const [deviceLogLength, setDeviceLogLength] = React.useState<number>(0)
  const xrinvData = useRef<XrinvResponseData>({data: [], state: null});
  const xritmData = useRef<XritmResponseData>({data: [], state: null, msn: ''});
  const xrdlgData = useRef<XrdlgResponseData>({data: [], state: null});

  useEffect(() => {
    init();
    setCommsCheckResultContext(commsCheckInitialData);
    sendSingleGenerateXmlXrinvXritm();
  }, []);

  const getXmlInitialTimeout = parseInt(`${process.env.REACT_APP_COMMS_CHECK_GET_XML_INITIAL_TIMEOUT}`, 10);
  const getXmlTimeout = parseInt(`${process.env.REACT_APP_COMMS_CHECK_GET_XML_TIMEOUT}`, 10);
  const maxRetries = parseInt(`${process.env.REACT_APP_COMMS_CHECK_GET_XML_MAX_RETRIES}`, 10);

  let retriesXrinv = 0;
  let retriesXritm = 0;
  let retriesXrdlg = 0;
  // This should always be updated through the updateProgress function to avoid inconsistent state
  const inProgress = useRef(false);

  function init() {
    retriesXrinv = 0;
    retriesXritm = 0;
    retriesXrdlg = 0;
    updateProgress(false);
    setCommsCheckResultContext(commsCheckInitialData);
    setStatusScreen('');
  }

  /**
   * Updates both, the inProgress variable used in the JS part of the component and the
   * automationInProgress state variable used in the HTML part of the component.
   *
   * These two variable should only be updated through this function to avoid inconsistent state.
   *
   * @param state - the new state
   */
  function updateProgress(state: boolean) {
    inProgress.current = state;
  }

  const sendSingleGenerateXmlXrinvXritm = () => {
    const request = buildXrinvRequest(mpxn);
    updateProgress(true);
    setStatusScreen('LOAD');
    apiCall(`${process.env.REACT_APP_SINGLE_GENERATE_XML_PROXY_LAMBDA_URL}/xrinv`, request)
      .then((httpResponse) => {
        if (httpResponse.status === 500) {
          inProgress.current = false;
          checkCompleted(proxy500L1error);
          throw new Error('L1 500 proxy error.');
        }
        return httpResponse.json()
      })
      .then((l1Response) => {
        console.warn('L1 XRINV 200 proxy success, L2 starts', l1Response);
        if (inProgress.current) {
          setTimeout(() => sendSingleGetXmlXrinv(l1Response.atomL1response, mpxn), getXmlInitialTimeout);
        }
      })
      .catch((e) => {
        console.warn('L1 XRINV 500 proxy fail, L2 WONT start', e);
      });

    const xritmRequest = buildXritmRequest(mpxn, msn);
    apiCall(`${process.env.REACT_APP_SINGLE_GENERATE_XML_PROXY_LAMBDA_URL}/xritm`, xritmRequest)
      .then((httpResponse) => {
        if (httpResponse.status === 500) {
          inProgress.current = false;
          checkCompleted(proxy500L1error);
          throw new Error('L1 500 proxy error.');
        }
        return httpResponse.json();
      })
      .then((l1Response) => {
        console.warn('L1 XRITM 200 proxy success, L2 starts', l1Response);
        if (inProgress.current) {
          setTimeout(() => sendSingleGetXmlXritm(l1Response.atomL1response), getXmlInitialTimeout);
        }
      })
      .catch((e) => {
        console.warn('L1 XRITM 500 proxy fail, L2 WONT start', e);
      });
  };

  const sendSingleGenerateXmlXrdlg = (request: any) => {
    apiCall(`${process.env.REACT_APP_SINGLE_GENERATE_XML_PROXY_LAMBDA_URL}/xrdlg`, request)
      .then((httpResponse) => {
        if (httpResponse.status === 500) {
          inProgress.current = false;
          checkCompleted(proxy500L1error);
          throw new Error('L1 500 proxy error');
        }
        return httpResponse.json();
      })
      .then((l1Response) => {
        console.warn('L1 XRDLG 200 proxy success, L2 starts', l1Response);
        if (inProgress.current) {
          setTimeout(() => sendSingleGetXmlXrdlg(l1Response.atomL1response), getXmlInitialTimeout);
        }
      })
      .catch((e) => {
        console.warn('L1 XRDLG 500 proxy fail, L2 WONT start', e);
      });
  };

  const sendSingleGetXmlXrinv = (data: any, mpxn: string) => {
    if (!inProgress.current) {
      return;
    }
    const xrinvGetXmlRequest = buildGetXmlRequest(data);
    apiCall(`${process.env.REACT_APP_SINGLE_GET_XML_PROXY_LAMBDA_URL}/xrinv`, xrinvGetXmlRequest)
      .then((httpResponse) => {
        if (httpResponse.status === 500) {
          if (inProgress.current && maxRetries > retriesXrinv) {
            retriesXrinv += 1;
            setTimeout(() => sendSingleGetXmlXrinv(data, mpxn), getXmlTimeout);
            throw new Error('L2 XRINV 500 proxy error, retrying');
          } else {
            inProgress.current = false;
            checkCompleted(proxy500L2error);
            throw new Error('L2 XRINV 500 proxy error, polling will end');
          }
        }
        return httpResponse.json();
      })
      .then((l2Response) => {
        if (l2Response.bolQueryState === 'FAIL') {
          xrinvData.current = {
            state: 'FAIL',
            data: [],
          };
          xrdlgData.current = {
            state: 'NOT_STARTED',
            data: [],
          };

          retriesXrinv = 0;
          checkCompleted({
            xrinv: xrinvData.current,
            xritm: xritmData.current,
            xrdlg: xrdlgData.current,
          });
        }

        if (l2Response.bolQueryState === 'PROCESSING') {
          if (inProgress.current && maxRetries > retriesXrinv) {
            retriesXrinv += 1;
            setTimeout(() => sendSingleGetXmlXrinv(data, mpxn), getXmlTimeout);
          } else {
            xrinvData.current = {
              state: l2Response.bolQueryState,
              data: getXrinvData(l2Response) || [],
            };
            xrdlgData.current = {
              state: 'FAIL',
              data: [],
            };

            retriesXrinv = 0;
            checkCompleted({
              xrinv: xrinvData.current,
              xritm: xritmData.current,
              xrdlg: xrdlgData.current,
            });
          }
        }

        if (l2Response.bolQueryState === 'SUCCESS') {
          xrinvData.current = {
            state: l2Response.bolQueryState,
            data: getXrinvData(l2Response),
          };

          const chfGuid = xrinvData.current.data.find((device) => device.deviceType === 'CHF')?.deviceId;
          const request = buildXrdlgRequest(mpxn, chfGuid);
          if (inProgress.current) {
            sendSingleGenerateXmlXrdlg(request);
          }

          retriesXrinv = 0;
        }
      })
      .catch((e) => {
        console.warn(e);
      });
  };

  const sendSingleGetXmlXritm = (data: any) => {
    if (!inProgress.current) {
      return;
    }
    const xritmGetXmlRequest = buildGetXmlRequest(data);
    apiCall(`${process.env.REACT_APP_SINGLE_GET_XML_PROXY_LAMBDA_URL}/xritm`, xritmGetXmlRequest)
      .then((httpResponse) => {
        if (httpResponse.status === 500) {
          if (inProgress.current && maxRetries > retriesXritm) {
            retriesXritm += 1;
            setTimeout(() => sendSingleGetXmlXritm(data), getXmlTimeout);
            throw new Error('L2 XRITM 500 proxy error, retrying');
          } else {
            inProgress.current = false;
            checkCompleted(proxy500L2error);
            throw new Error('L2 XRITM 500 proxy error, polling will end');
          }
        }
        return httpResponse.json()
      })
      .then((l2Response) => {
        if (l2Response.bolQueryState === 'FAIL') {
          xritmData.current = {
            state: 'FAIL',
            data: [],
            msn: msn,
          };

          retriesXritm = 0;
          checkCompleted({
            xrinv: xrinvData.current,
            xritm: xritmData.current,
            xrdlg: xrdlgData.current,
          });
        }

        if (l2Response.bolQueryState === 'PROCESSING') {
          if (inProgress.current && maxRetries > retriesXritm) {
            retriesXritm += 1;
            setTimeout(() => sendSingleGetXmlXritm(data), getXmlTimeout);
          } else {

            xritmData.current = {
              state: l2Response.bolQueryState,
              data: getXritmData(l2Response) || [],
              msn: msn,
            };

            retriesXritm = 0;
            checkCompleted({
              xrinv: xrinvData.current,
              xritm: xritmData.current,
              xrdlg: xrdlgData.current,
            });
          }
        }

        if (l2Response.bolQueryState === 'SUCCESS') {
          xritmData.current = {
            state: l2Response.bolQueryState,
            data: getXritmData(l2Response) || [],
            msn: l2Response.atomL2response['0']?.xml_string?.Body?.XRITM_OUT_02?.MSN,
          };

          retriesXritm = 0;
          checkCompleted({
            xrinv: xrinvData.current,
            xritm: xritmData.current,
            xrdlg: xrdlgData.current,
          });
        }
      })
      .catch((e) => {
        console.warn(e);
      });
  };

  const sendSingleGetXmlXrdlg = (data: any) => {
    if (!inProgress.current) {
      return;
    }
    const request = buildGetXmlRequest(data);
    apiCall(`${process.env.REACT_APP_SINGLE_GET_XML_PROXY_LAMBDA_URL}/xrdlg`, request)
      .then((httpResponse) => {
        if (httpResponse.status === 500) {
          if (inProgress.current && maxRetries > retriesXrdlg) {
            retriesXrdlg += 1;
            setTimeout(() => sendSingleGetXmlXrdlg(data), getXmlTimeout);
            throw new Error('L2 XRDLG 500 proxy error, retrying');
          } else {
            inProgress.current = false;
            checkCompleted(proxy500L2error);
            throw new Error('L2 XRDLG 500 proxy error, polling will end');
          }
        }
        return httpResponse.json()
      })
      .then((response) => {
        if (response.bolQueryState === 'FAIL') {
          xrdlgData.current = {
            state: 'FAIL',
            data: [],
          };

          retriesXrdlg = 0;
          checkCompleted({
            xrinv: xrinvData.current,
            xritm: xritmData.current,
            xrdlg: xrdlgData.current,
          });
        }

        if (response.bolQueryState === 'PROCESSING') {
          if (inProgress.current && maxRetries > retriesXrdlg) {
            retriesXrdlg += 1;
            setTimeout(() => sendSingleGetXmlXrdlg(data), getXmlTimeout);
          } else {
            xrdlgData.current = {
              state: response.bolQueryState,
              data: [],
            };

            retriesXrdlg = 0;
            checkCompleted({
              xrinv: xrinvData.current,
              xritm: xritmData.current,
              xrdlg: xrdlgData.current,
            });
          }
        }

        if (response.bolQueryState === 'SUCCESS') {
          const allXrdlgResponseData = getXrdlgData(response);
          const deviceInventoryIds = xrinvData.current.data.map((device) => device.deviceId);
          const otherDevices = allXrdlgResponseData
            .filter((device: XrdlgData) => !deviceInventoryIds.includes(device.deviceId))

          xrdlgData.current = {
            state: response.bolQueryState,
            data: otherDevices,
          };

          allXrdlgResponseData.forEach((dlgDevice: XrdlgData) => {
            xrinvData.current.data = xrinvData.current.data.map((invDevice) => {
              const resultDevice: XrinvData = {
                deviceId: invDevice.deviceId,
                deviceType: invDevice.deviceType,
                deviceStatus: invDevice.deviceStatus,
                lastCommunicatedTime: invDevice.lastCommunicatedTime,
                mpxn: invDevice.mpxn,
                smetsDeviceType: invDevice.smetsDeviceType,
              };

              if (invDevice.deviceId === dlgDevice.deviceId) {
                resultDevice.lastCommunicatedTime = dlgDevice.lastCommunicatedTime;
              }

              return resultDevice;
            });
          });

          retriesXrdlg = 0;
          checkCompleted({
            xrinv: xrinvData.current,
            xritm: xritmData.current,
            xrdlg: xrdlgData.current,
          });
        }
      })
      .catch((e) => {
        console.warn(e);
      });
  };

  function parseSmetsVersion(smetsChtsVersion: string) {
    if (!smetsChtsVersion) {
      return  '';
    }

    // smetsChtsVersion in the raw response is like - "V4.2"
    // SMETS 2 meter will have version greater or equal than 2.0
    if (parseInt(smetsChtsVersion.charAt(1)) < 2) {
      return 'SMETS 1';
    } else {
      return 'SMETS 2';
    }
  }

  function getXrinvData(l2Response: any) {
    return l2Response.atomL2response['0']?.xml_string?.Body?.XRINV_OUT_02?.InventoryDevice?.map((device: any) => ({
      deviceId: device.DeviceID,
      deviceType: device.DeviceType,
      deviceStatus: device.DeviceStatus,
      mpxn: device.ImportMPXN,
      smetsDeviceType: parseSmetsVersion(device.SMETSCHTSVersion),
    }));
  }

  function getXritmData(l2Response: any) {
    const readingRegister = l2Response.atomL2response['0']?.xml_string?.Body?.XRITM_OUT_02?.Readings?.TOURegister;
    if (!readingRegister) {
      return [];
    }

    if (Array.isArray(readingRegister)) {
      return readingRegister.map((register: any) => ({
        registerId: register.RegisterID,
        reading: register.Reading,
      }));
    }

    return [{
      registerId: readingRegister.RegisterID,
      reading: readingRegister.Reading,
    }];
  }

  function getXrdlgData(l2dlgResponse: any): XrdlgData[] {
    const deviceLogEntry = l2dlgResponse.atomL2response['0']?.xml_string?.Body?.XRDLG_OUT_02?.DeviceLogEntry;
    if (!deviceLogEntry) {
      return [];
    }

    if (Array.isArray(deviceLogEntry)) {
      return deviceLogEntry.map((device: any) => ({
        deviceId: device.DeviceID,
        lastCommunicatedTime: makeReadableTimeStamp(device.LastCommunicationTime),
      }));
    }

    return [{
      deviceId: deviceLogEntry.DeviceID,
      lastCommunicatedTime: makeReadableTimeStamp(deviceLogEntry.LastCommunicationTime),
    }];
  }

  function checkCompleted(data: CommsCheckResult) {
    console.log('Checking data...');
    // Check if all three requests have been completed
    if (data.xrinv.state && data.xritm.state && data.xrdlg.state) {
      if (data.xrinv.state === 'SUCCESS' && data.xritm.state === 'SUCCESS' && data.xrdlg.state === 'SUCCESS') {
        setStatusScreen('SUCCESS');
        setCommsCheckResultContext(data);
        setDeviceLogLength(data.xrdlg.data.length);
        return;
      }

      setStatusScreen('FAIL');
      setCommsCheckResultContext(data);
    }
  }

  return (
    <div className="commsCheckContainer" id="commsCheckContainer" data-testid="comms-check-v2">
      {
        statusScreen === 'LOAD' && <WaitingForResponse/>
      }
      {
        statusScreen === 'SUCCESS' &&
        <CommsCheckSuccessResult commsCheckData={commsCheckResultContext}
                                 deviceLogLength={deviceLogLength}/>
      }
      {
        statusScreen === 'FAIL' &&
        <CommsCheckFailResult failScreenData={buildFailScreenData(commsCheckResultContext, msn)}
                              commsCheckData={commsCheckResultContext}/>
      }
    </div>
  );
}

export function buildChatData(data: CommsCheckResult, mpxn: string, msn: string) {
  const chf = data.xrinv.data.find((device) => device.deviceType === 'CHF')?.deviceId;
  const chfText = chf ? `CHF: ${chf}\n` : '';
  const deviceData = data.xrinv.data.map((invDevice) => {
    const deviceLct = invDevice.lastCommunicatedTime ? `LCT: ${makeReadableTimeStamp(invDevice.lastCommunicatedTime)}\n\n` : '\n';

    return `${invDevice.deviceType}:
      Status: ${invDevice.deviceStatus}
      GUID: ${invDevice.deviceId}
      ${deviceLct}`;
  });

  const successfulInformation = deviceData.length ? `Successful Information:\n${deviceData.reduce((acc, device) => `${acc}${device}`)}` : '';

  if (data.xrinv.state === 'SUCCESS' && data.xritm.state === 'SUCCESS' && data.xrdlg.state === 'SUCCESS') {
    return successfulInformation;
  }

  const failScreenData = buildFailScreenData(data, msn);
  const failDataXrinv = failScreenData.xrinvText ? `${failScreenData.xrinvText}\n` : '';
  const failDataXritm = failScreenData.xritmText ? `${failScreenData.xritmText}\n` : '';
  const failDataXrdlg = failScreenData.xrdlgText ? `${failScreenData.xrdlgText}\n` : '';
  const failInformation = `${failScreenData.information}:\n${failDataXrinv}${failDataXritm}${failDataXrdlg}`;

  return `MPxN: ${mpxn}
            MSN: ${msn}
            ${chfText}
            ${(failScreenData.xrinvText || failScreenData.xritmText || failScreenData.xrdlgText) ? failInformation : ''}
            ${!(failScreenData.xrinvText && failScreenData.xritmText && failScreenData.xrdlgText) ? successfulInformation : ''}`;
}

export function buildFailScreenData(data: CommsCheckResult, msn: string) {
  const unableToDetermineAction = 'Unable to determine comms result, please check the MPxN and MSN and try again. If this issue persists, contact SST.';
  const firstUnsuccessfulResponse = 'If this is the first unsuccessful response, please check the MPxN and MSN. If correct, perform a comms hub reboot and try again. If the issue persists, please contact SST.';
  const firstUnsuccessfulResponse2 = 'If this is the first unsuccessful response, please check the MPxN and MSN, and then try again. If the issue persists, contact SST.';
  const unableToRetrieveDevices = 'Unable to retrieve devices from the DCC';
  const unableToRetrieveReadings = `Unable to retrieve readings for MSN: ${msn}`;
  const unableToRetrieveLct = 'Unable to retrieve last communicated time for the devices';
  const failInformation = 'Fail Information';
  const timeoutInformation = 'Timeout Information';

  const result = determineCommsCheckFailResult(data);
  let action;
  let xrinvText;
  let xritmText;
  let xrdlgText;
  let information;

  if (data.xrinv.state === 'FAIL' && data.xritm.state === 'FAIL') {
    action = unableToDetermineAction;
    xrinvText = unableToRetrieveDevices;
    xritmText = unableToRetrieveReadings;
    xrdlgText = unableToRetrieveLct;
    information = failInformation;
  } else if (data.xrinv.state === 'SUCCESS' && data.xritm.state === 'FAIL' && data.xrdlg.state === 'FAIL') {
    action = firstUnsuccessfulResponse;
    xrinvText = null;
    xritmText = unableToRetrieveReadings;
    xrdlgText = unableToRetrieveLct;
    information = failInformation;
  } else if (data.xrinv.state === 'FAIL' && data.xritm.state === 'SUCCESS') {
    action = unableToDetermineAction;
    xrinvText = unableToRetrieveDevices;
    xritmText = null;
    xrdlgText = unableToRetrieveLct;
    information = failInformation;
  } else if (data.xrinv.state === 'SUCCESS' && data.xritm.state === 'SUCCESS' && data.xrdlg.state === 'FAIL') {
    action = firstUnsuccessfulResponse2;
    xrinvText = null;
    xritmText = null;
    xrdlgText = unableToRetrieveLct;
    information = failInformation;
  } else if (data.xrinv.state === 'SUCCESS' && data.xritm.state === 'FAIL' && data.xrdlg.state === 'SUCCESS') {
    action = 'If this is the first unsuccessful response, please check the MPxN, MSN and CHF. If correct, perform a power cycle and try again. If the issue persists, please contact SST.';
    xrinvText = null;
    xritmText = unableToRetrieveReadings;
    xrdlgText = null;
    information = failInformation;
    // TIMEOUT cases
  } else if (data.xrinv.state === 'PROCESSING' && data.xritm.state === 'PROCESSING') {
    action = unableToDetermineAction;
    xrinvText = unableToRetrieveDevices;
    xritmText = unableToRetrieveReadings;
    xrdlgText = unableToRetrieveLct;
    information = timeoutInformation;
  } else if (data.xrinv.state === 'PROCESSING' && data.xritm.state === 'SUCCESS') {
    action = unableToDetermineAction;
    xrinvText = unableToRetrieveDevices;
    xritmText = null;
    xrdlgText = unableToRetrieveLct;
    information = timeoutInformation;
  } else if (data.xrinv.state === 'SUCCESS' && data.xritm.state === 'FAIL' && data.xrdlg.state === 'PROCESSING') {
    action = firstUnsuccessfulResponse;
    xrinvText = null;
    xritmText = unableToRetrieveReadings;
    xrdlgText = unableToRetrieveLct;
    information = timeoutInformation;
  } else if (data.xrinv.state === 'PROCESSING' && data.xritm.state === 'FAIL') {
    action = unableToDetermineAction;
    xrinvText = unableToRetrieveDevices;
    xritmText = unableToRetrieveReadings;
    xrdlgText = unableToRetrieveLct;
    information = timeoutInformation;
  } else {
    action = firstUnsuccessfulResponse2;
    xrinvText = '';
    xritmText = '';
    xrdlgText = '';
    information = failInformation;
  }

  return {
    result,
    action,
    xrinvText,
    xritmText,
    xrdlgText,
    information,
  }
}

export function determineCommsCheckFailResult(data: CommsCheckResult): string {
  if (
    data.xritm.state === 'FAIL' ||
    (data.xritm.state === 'SUCCESS' && data.xrdlg.state === 'FAIL')
  ) {
    return 'Not in comms';
  }
  return 'Unable to be determined';
}
