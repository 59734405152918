import React, { FC, useState } from 'react';
import {
  RadioInputAnswerOptions,
  RadioInputQuestion,
  RadioInputQuestionType,
} from '../common/radioInputQuestion/RadioInputQuestion';
import { InformationPanel, TextParagraph } from '../common/informationPanel/InformationPanel';
import { useDecommissionInputsContext } from '../../context/DecommissionContext';
import { ButtonContainer } from '../common/buttonContainer/ButtonContainer';
import { Button, ButtonTheme } from '../common/button/Button';
import { TextInput } from '../common/textInput/TextInput';
import { signOut } from 'aws-amplify/auth';
import { getMpxnError, getMsnError, isMpxnValid, isMsnValid } from '../util';
import {
  EmptySpaceForFixedButtons,
} from '../common/emptySpaceForFixedButtons/EmptySpaceForFixedButtons';

export interface InitialQuestionsProps {
  primaryButtonHandler: () => void;
}

export const InitialQuestions: FC<InitialQuestionsProps> = ({primaryButtonHandler}) => {
  const { decommissionInputs, setDecommissionInputs } = useDecommissionInputsContext();
  const [showMpxnError, setShowMpxnError] = useState(false);
  const [mpxnErrorMessage, setMpxnErrorMessage] = useState('');
  const [showMsnError, setShowMsnError] = useState(false);
  const [msnErrorMessage, setMsnErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);

  const onsiteErrorMessage: string ='You need to be onsite to continue';
  const onsiteRadioQuestion: RadioInputQuestionType = { question: 'Are you onsite?' };
  const onsiteRadioAnswers: RadioInputAnswerOptions = {
    options: [
      {
        answerValue: 'Yes',
        inputName: 'yes',
        radioBtnGroupName: 'onsiteQuestionGroup'
      }, {
        answerValue: 'No',
        inputName: 'no',
        radioBtnGroupName: 'onsiteQuestionGroup'
      }]
  }

  const meterTypeRadioQuestions: RadioInputQuestionType = { question: 'Which meter are you decommissioning?'}

  const meterTypeRadioAnswers: RadioInputAnswerOptions = {
    options: [
      {
        answerValue: 'Electric',
        inputName: 'electric',
        radioBtnGroupName: 'meterTypeQuestionGroup'
      }, {
        answerValue: 'Gas',
        inputName: 'gas',
        radioBtnGroupName: 'meterTypeQuestionGroup'
      }]
  }

  const onsiteReminderPanelText: TextParagraph[] = [
    {
      text: 'Please do not attempt a decommission if you are not onsite.',
      lineBreak: false
    }
  ];

  const noMsnText: TextParagraph[] = [
    {
      text: 'No MSN? Select "Checking site details" in the issue dropdown to get this.',
      lineBreak: false
    }
  ];

  const readyToStartCommsCheckText: TextParagraph[] = [
    {
      text: 'Before you can decommission this meter, we need to run a quick comms check.',
      lineBreak: false
    }
  ];

  function onsiteRadioQuestionHandler(event: React.ChangeEvent<HTMLInputElement>) {
    setDecommissionInputs({...decommissionInputs, onsiteRadioAnswer: event.target.value.trim()});
    setShowError(false);
    if(event.target.value.trim() === 'No') {
      setShowError(true);
    }
  }

  function meterTypeRadioQuestionHandler(event: React.ChangeEvent<HTMLInputElement>) {
    setDecommissionInputs({...decommissionInputs, meterTypeRadioAnswer: event.target.value.trim()});
  }

  function validDecommissionAnswers(): boolean {
    if(showMpxnError || decommissionInputs.mpxn == '') {
      return false;
    }
    if(showMsnError || decommissionInputs.msn == '') {
      return false;
    }
    if(decommissionInputs.meterTypeRadioAnswer == '') {
      return false;
    }
    return true;
  }

  function mpxnTextInputOnChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    setDecommissionInputs({...decommissionInputs, mpxn: event.target.value});
    if(!isMpxnValid(event.target.value)) {
      setShowMpxnError(true);
      setMpxnErrorMessage(getMpxnError(event.target.value));
      return;
    } else {
      setShowMpxnError(false);
      return;
    }
  }

  function msnTextInputOnChangeHandler(event: React.ChangeEvent<HTMLInputElement>) {
    setDecommissionInputs({...decommissionInputs, msn: event.target.value});
    if(!isMsnValid(event.target.value)) {
      setShowMsnError(true);
      setMsnErrorMessage(getMsnError(event.target.value));
      return;
    } else {
      setShowMsnError(false);
      return;
    }
  }

  return (
    <>
      <RadioInputQuestion question={onsiteRadioQuestion} answers={onsiteRadioAnswers} onChangeHandler={onsiteRadioQuestionHandler} showError={showError} errorMessage={onsiteErrorMessage}/>
      { decommissionInputs.onsiteRadioAnswer !== 'Yes' &&
          <InformationPanel textArray={onsiteReminderPanelText} title={null} background={true} dataTestId={'info-panel-decomm-onsite-reminder'}/>
      }
      { decommissionInputs.onsiteRadioAnswer === 'Yes' &&
        <>
          <RadioInputQuestion question={meterTypeRadioQuestions} answers={meterTypeRadioAnswers} onChangeHandler={meterTypeRadioQuestionHandler}/>
          <TextInput label="Enter MPxN for the property" inputName="mpxn" inputValue={decommissionInputs.mpxn} showError={showMpxnError} errorMessage={mpxnErrorMessage} onChangeHandler={mpxnTextInputOnChangeHandler} />
          <TextInput label={`Enter your ${decommissionInputs.meterTypeRadioAnswer} MSN`} inputName="msn" inputValue={decommissionInputs.msn} showError={showMsnError} errorMessage={msnErrorMessage} onChangeHandler={msnTextInputOnChangeHandler} />
          {decommissionInputs.msn == '' ? <InformationPanel title={null} textArray={noMsnText} background={true}/> : null}
          {validDecommissionAnswers() && <InformationPanel title={null} textArray={readyToStartCommsCheckText} background={false} />}
        </>
      }
      <ButtonContainer>
        <Button buttonId="start-comms-check-decommission" buttonText="Start comms check" buttonTheme={ButtonTheme.PRIMARY} isButtonEnabled={validDecommissionAnswers()}
                onClickHandler={primaryButtonHandler} />
        <Button buttonId="log-out-decommission-initial" buttonText="Log out" buttonTheme={ButtonTheme.SECONDARY} dataTestId="log-out-btn"
                onClickHandler={signOut} />
      </ButtonContainer>
      <EmptySpaceForFixedButtons numberOfButtons={2}/>
    </>
  );
}
