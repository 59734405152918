import React, { FC, Fragment } from 'react';
import './RadioInputQuestion.css';

export interface RadioInputQuestionProps {
  question: RadioInputQuestionType;
  answers: RadioInputAnswerOptions;
  errorMessage?: string;
  showError?: boolean;
  onChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface RadioInputQuestionType {
  question: string;
}

export interface RadioInputAnswerOption {
  answerValue: string;
  inputName: string;
  radioBtnGroupName: string;
}

export interface RadioInputAnswerOptions {
  options: RadioInputAnswerOption[];
}

export const RadioInputQuestion: FC<RadioInputQuestionProps> = ({question, answers, onChangeHandler, errorMessage, showError = false}) => {
  return (
    <div className="radio-input-question-container">
      <p className="radio-question-p">{question.question}</p>
      { showError && <p className="radio-question-error-p">{errorMessage}</p>}
      {answers.options.map(({answerValue, inputName, radioBtnGroupName}, index: number) => {
        return (
          <Fragment key={index}>
            {index > 0 && <hr className="solid"/>}
            <div className="radio-input-answer-container">
              <label htmlFor={inputName} className="radio-input-answer-label">{answerValue}</label>
              <input type="radio" className="radio-input-answer-btn" id={inputName} name={radioBtnGroupName}
                     value={answerValue}
                     onChange={onChangeHandler}/>
            </div>
          </Fragment>
        )
      })
      }
    </div>
  );
}
