import { createContext, useContext } from 'react';

export interface DecommissionInputs {
  onsiteRadioAnswer: string;
  meterTypeRadioAnswer: string;
  mpxn: string;
  msn: string;
}

interface DecommissionInputsContextProps {
  decommissionInputs: DecommissionInputs;
  setDecommissionInputs: (decommissionInputs: DecommissionInputs) => void;
}

export const decommissionInputsInitialState: DecommissionInputs = {
  onsiteRadioAnswer: '',
  meterTypeRadioAnswer: '',
  mpxn: '',
  msn: '',
};

export const DecommissionInputsContext = createContext<DecommissionInputsContextProps | null>({
  decommissionInputs: decommissionInputsInitialState,
  setDecommissionInputs: () => {},
});

export const useDecommissionInputsContext = (): DecommissionInputsContextProps => {
  const context = useContext(DecommissionInputsContext);
  if (!context) {
    throw new Error('useDecommissionInputsContext must be used within a DecommissionInputsProvider');
  }
  return context;
};

export enum DecommissionScreenType {
  INITIAL, COMMS_CHECK, SELECT_DECOMMISSION_REASON, DECOMMISSION_SUMMARY,
}

interface DecommissionScreenContextProps {
  screen: DecommissionScreenType;
  setScreen: (screen: DecommissionScreenType) => void;
}

export const DecommissionScreenContext = createContext<DecommissionScreenContextProps>({
  screen: DecommissionScreenType.INITIAL,
  setScreen: () => {},
});

export const useDecommissionScreenContext = (): DecommissionScreenContextProps => {
  const context = useContext(DecommissionScreenContext);
  if (!context) {
    throw new Error('useDecommissionScreenContext must be used within a DecommissionScreenProvider');
  }
  return context;
};

export enum DecommissionReasonType {
  MeterIsDamaged = 'Meter is damaged',
  Complaint = 'Complaint',
  NonComm = 'Non comm',
  NoRoomForHotShoe = 'No room for hot shoe',
  NoCommsAfterPowerCycle = 'No comms after power cycle',
  NonFaultyMeter = 'Non faulty meter',
}

interface DecommissionReasonProps {
  decommissionReason: DecommissionReasonType | undefined;
  setDecommissionReason: (type: DecommissionReasonType) => void;
}

export const DecommissionReasonContext = createContext<DecommissionReasonProps>({
  decommissionReason: undefined,
  setDecommissionReason: () => {},
});

export const useDecommissionReasonContext = (): DecommissionReasonProps => {
  const context = useContext(DecommissionReasonContext);
  if (!context) {
    throw new Error('useDecommissionScreenContext must be used within a DecommissionScreenProvider');
  }
  return context;
};
