import { CommsCheckResult } from '../types/comms-check';
import {createContext, useContext} from 'react';

const commsCheckInitialData: CommsCheckResult = {
  xrinv: {
    data: [],
    state: null,
  },
  xritm: {
    data: [],
    state: null,
    msn: '',
  },
  xrdlg: {
    data: [],
    state: null,
  },
};

export default commsCheckInitialData;

interface CommsCheckContextProps {
  commsCheckResultContext: CommsCheckResult;
  setCommsCheckResultContext: (commsCheckResult: CommsCheckResult) => void;
}

export const CommsCheckContext = createContext<CommsCheckContextProps>({
  commsCheckResultContext: commsCheckInitialData,
  setCommsCheckResultContext: () => {},
});

export const useCommsCheckContext = (): CommsCheckContextProps => {
  const context = useContext(CommsCheckContext);
  if (!context) {
    throw new Error('useCommsCheckContext must be used within a CommsCheckContext Provider');
  }
  return context;
};