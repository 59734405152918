import {CommsCheckResult} from '../../types/comms-check';

export const proxy500L1error: CommsCheckResult = {
  xrinv: { data: [], state: 'PROXY_L1_ERROR' },
  xritm: { data: [], state: 'PROXY_L1_ERROR', msn: '' },
  xrdlg: { data: [], state: 'PROXY_L1_ERROR' },
};

export const proxy500L2error: CommsCheckResult = {
  xrinv: { data: [], state: 'PROXY_L2_ERROR' },
  xritm: { data: [], state: 'PROXY_L2_ERROR', msn: '' },
  xrdlg: { data: [], state: 'PROXY_L2_ERROR' },
};
