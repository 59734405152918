import React, {FC} from 'react';
import './Button.css'

export interface ButtonProps {
  isButtonEnabled?: boolean;
  buttonId: string;
  buttonText: string;
  buttonTheme: ButtonTheme;
  dataTestId?: string;
  onClickHandler: () => void;
}

export enum ButtonTheme {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DANGER = 'danger'
}

function getButtonClassName(buttonTheme: ButtonTheme) {
  switch (buttonTheme) {
    case ButtonTheme.PRIMARY:
      return 'btn primaryBtn';
    case ButtonTheme.SECONDARY:
      return 'btn secondaryBtn';
    case ButtonTheme.DANGER:
      return 'btn dangerBtn';
    default:
      return 'btn';
  }
}

export const Button: FC<ButtonProps> = ({isButtonEnabled = true, buttonId, buttonText, buttonTheme, onClickHandler, dataTestId}) => {
  return (
    <button
      className={getButtonClassName(buttonTheme)}
      id={buttonId}
      onClick={onClickHandler}
      disabled={!isButtonEnabled}
      data-testid={dataTestId ?? ''}
    >{buttonText}</button>
  );
}
