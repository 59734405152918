import { createContext, useContext } from 'react';

export type HomePageState = {
  automationInProgress: boolean;
  brandSelected: string;
};

interface HomePageContextProps {
  homePageState: HomePageState;
  setHomePageState: (homePageState: HomePageState) => void;
}

export const homePageInitialState: HomePageState = {
  automationInProgress: false,
  brandSelected: '',
};

export const HomePageContext = createContext<HomePageContextProps>({
  homePageState: homePageInitialState,
  setHomePageState: () => {},
});

export const useHomePageContext = (): HomePageContextProps => {
  const context = useContext(HomePageContext);
  if (!context) {
    throw new Error('useHomePageContext must be used within a HomePageProvider');
  }
  return context;
};

type ChatReasonType = {
  chatReason: string;
  setChatReason: (reason: string) => void;
}

export const ChatReasonContext = createContext<ChatReasonType>({
  chatReason: '',
  setChatReason: () => {},
});

export const useChatReasonContext = (): ChatReasonType => {
  const context = useContext(ChatReasonContext);
  if (!context) {
    throw new Error('useChatReasonContext must be used within a ChatReasonContextProvider');
  }
  return context;
};
