/* eslint-disable */
import $ from 'jquery';
import {boostSelectOptions, ovoSelectOptions, validateStartChatInfo} from "./chat-reasons-v2";

const DEFAULT_CHAT_REASON_LEVEL = '3';

export function getParamsFromURL() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(urlSearchParams.entries());
}

window.onbeforeunload = () => 'Reload?';

$(document).ready(() => {
   
  connect.ChatInterface.init({
    containerId: 'root',
    headerConfig: {
      isHTML: true,
      render: () => (`
                <div class="header-wrapper" style="display: none;">
                <div style="display=flex;">
                <div>
                    <h2 class="welcome-text">Metering Installation Support</h2>
                    <p id="chatDescription"></p>
                </div>
                `),
    },
  });
});

const checkValueIsPresent = (argument) => !!argument;

function findChatReasonLevel(list, chatReason) {
  return list.find((option) => option.text === chatReason)?.queue ?? DEFAULT_CHAT_REASON_LEVEL;
}

function getChatReasonLevel(chatReason, selectedBrand) {
  if (selectedBrand === 'OVO') {
     
    return findChatReasonLevel(ovoSelectOptions, chatReason);
  } if (selectedBrand === 'BOOST') {
     
    return findChatReasonLevel(boostSelectOptions, chatReason);
  }
  return DEFAULT_CHAT_REASON_LEVEL;
}

export function startChat(commsCheckResponseData, sessionUserEmail, accessToken, mpxn) {
  if (!validateStartChatInfo()) {
    return;
  }

  const userDomainArray = sessionUserEmail.split('@');
  const sessionUserName = userDomainArray[0];
  const sessionEmailDomain = userDomainArray[1];
  const selectedBrand = $('input[name=brand]:checked').val();
  const chatReason = $('.dd-selected-text').text();
  const chatReasonLevel = getChatReasonLevel(chatReason, selectedBrand);
  if (checkValueIsPresent(sessionUserName)
    && checkValueIsPresent(accessToken)
    && checkValueIsPresent(sessionEmailDomain)
    && checkValueIsPresent(chatReason)
    && checkValueIsPresent(selectedBrand)) {

    const connectParams = getParamsFromURL();
    connectParams.customerName = sessionUserName;
    connectParams.emailDomain = sessionEmailDomain;
    connectParams.chatReason = chatReason;
    connectParams.chatReasonLevel = chatReasonLevel;
    connectParams.brand = selectedBrand;
    connectParams.commsCheckResponseData = commsCheckResponseData;
    if(!checkValueIsPresent(connectParams.mpxn)) {
      !!mpxn ? connectParams.mpxn = mpxn : null;
    }

    $('.divSpinner').delay(10).fadeIn();
    $('#chatWrapper').css('display', 'block');
    $('.limiter').fadeOut();
    const shadesEl = document.querySelector('.connect-customer-interface');
    shadesEl.classList.remove('sc-gipzik');
    shadesEl.classList.remove('eYCHsU');
    const username = 'UserName';
    connect.ChatInterface.initiateChat({
      headers: {
        Authorizer: accessToken,
      },
      name: sessionUserName,
      username,
      region: process.env.REACT_APP_AWS_REGION,
      apiGatewayEndpoint: process.env.REACT_APP_AWS_LAMBDA_CHECK_ACTIVE_CHAT_URL,
      contactAttributes: JSON.stringify(connectParams),
    }, successHandler, failureHandler);

    $('#chatWrapper').hide();
    $('#section-chat').show('slide');
  }
}

function successHandler(chatSession) {
  $('#accessDeniedError').hide();
  window.chatSession = chatSession;
   
  connect.ChatSession.setGlobalConfig({
    loggerConfig: {
      logger: {
        error: (msg) => console.error(`Connect Chat Session message:|| ${msg}`),
      },
       
      level: connect.ChatSession.LogLevel.ERROR,
    },
    region: process.env.REACT_APP_AWS_REGION,
  });

   
  connect.WebSocketManager.setGlobalConfig({
    loggerConfig: {
      logger: {
        error: (msg) => console.error(`Connect Websocket Manager message:|| ${msg}`),
      },
       
      level: connect.WebSocketManager.LogLevel.ERROR,
    },
  });

   
  chatSession.incomingItemDecorator = (item) => {
    if (['SYSTEM_MESSAGE'].indexOf(item.displayName) !== -1) {
       
      item.displayName = 'MILES';
    }

    if (item.displayName === 'BOT') {
       
      item.displayName = 'MILES';
    }
    if (item.displayName === 'SYSTEM_MESSAGE') {
       
      item.displayName = 'MILES';
    }

    if (chatSession.transcript.length > 0) {
      // chat connected
      $('.divSpinner').hide();
      $('#chatWrapper').show();
      const transcriptItem = chatSession.transcript[chatSession.transcript.length - 1];
      if (transcriptItem.transportDetails.direction === 'Incoming') {
        let chatDescription = 'This is a demo of a customer chat experience.';
        const name = transcriptItem.displayName;
        if (['prod', '$LATEST', 'AI Assistant', 'SYSTEM_MESSAGE', 'System Message'].indexOf(name) === -1) {
          chatDescription = `You are now chatting with ${name}`;
        }
        document.getElementById('chatDescription').innerHTML = chatDescription;
      }
    }

    return item;
  };

  chatSession.onIncoming((data) => {
    console.log(`incoming message:|| ${JSON.stringify(data)}`);
    const myAudio = document.getElementById('my-audio');
    myAudio.play();
  });

  chatSession.onOutgoing((data) => {
    console.log(`outgoing message: ${JSON.stringify(data)}`);
  });

  chatSession.onChatDisconnected(() => {
    resetForm();
  });

  function resetForm() {
    $('#section-chat').removeAttr('style');
    $('#nav').css('width', '690');
    $('#section-chat').hide('slide');
    $('.limiter').fadeIn(200);
    window.chatSession = '';
  }

   
  connect.ChatInterface.init({
    containerId: 'root',
    headerConfig: {
      isHTML: true,
      render: () => (`
                <div class="header-wrapper" style="display: none;">
                <div style="display=flex;">
                <div>
                    <h2 class="welcome-text">Metering Installation Support</h2>
                    <p id="chatDescription"></p>
                </div>
                `),
    },
  });
}

function failureHandler(error) {
  console.log(error);
  setTimeout(() => {
    $('#accessDeniedError').hide();
    if (error.status === 401) {
      $('.divSpinner').hide();
      $('#section-chat').hide();
      $('.limiter').show();
    } else// Access denied error case
    if (error._debug !== undefined) {
      const errorConnectSuccess = error.connectSuccess;
      const errorAccessDenied = error._debug._debug.message;
      const errorReason = error._debug.reason;
      if (!errorConnectSuccess && errorAccessDenied === 'Access denied' && errorReason === 'Failed to fetch connectionDetails with createParticipantConnection') {
        $('#accessDeniedError').show();
        $('.divSpinner').hide();
        $('#section-chat').hide();
        $('.limiter').show();
      }
    } else {
      $('.divSpinner').hide();
      $('#section-chat').hide();
      $('.limiter').show();
    }
  }, 500);
}

export default function empty() {}