import React, {useEffect} from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import cognitoAuthConfig from './setup/cognito-auth-config';
import HomePage from './components/homepage/HomePage';
import ProtectedRoute from './setup/ProtectedRoute';
import {LDClient, useLDClient} from 'launchdarkly-react-client-sdk';
import {getCurrentUser, AuthUser} from 'aws-amplify/auth';

Amplify.configure(cognitoAuthConfig);

const router = createBrowserRouter([
  { path: '/', element: <ProtectedRoute element={<HomePage />} /> },
  { path: '/index.html', element: <ProtectedRoute element={<HomePage />} /> },
  { path: '/chat', element: <ProtectedRoute element={<HomePage />} /> },
  { path: '/chat.html', element: <ProtectedRoute element={<HomePage />} /> },
]);

export default function App() {
  return (
    <Authenticator hideSignUp>
      <AuthenticatedApp/>
    </Authenticator>
  );
}

function AuthenticatedApp() {
  const ldClient = useLDClient();

  useEffect(() => {
    getCurrentUser().then((user) => {
      configureUserContextForLDClient(ldClient, user);
    });
  }, []);

  return <RouterProvider router={router} />;
}

function configureUserContextForLDClient(ldClient: LDClient | undefined, user: AuthUser | undefined) {
  if (!ldClient) {
    console.error('Cannot configure user context for LD, because LDClient is not accessible. All LD users will be anonymous.');
    return;
  }
  if (!user) {
    console.error('Cannot configure user context for LD Client, because user session is not accessible. All LD users will be anonymous.');
    return;
  }
  ldClient.identify({
    'kind': 'user',
    'key': user.userId,
    'email': user.signInDetails?.loginId ?? ''
  });
}