import React from 'react';
import { XrinvData } from '../../types/comms-check';

export const InventoryDataSection = ({ data }: { data: XrinvData[] }) => {
  return (
    <>
      {
        data.map(({ deviceId, deviceType, deviceStatus, lastCommunicatedTime }) =>
          <div key={deviceId} className={'informationSection'} data-testid={`info-section-device-${deviceType}`}>
            <div className={'informationSectionText'}>{deviceType}:</div>
            <div className={'informationRow'}>
              <div>
                <span className={'informationSectionText'}>{`Status: ${deviceStatus}`}</span>
              </div>
              <div>
                <span className={'informationSectionText'}>{`GUID: ${deviceId}`}</span>
              </div>
              {lastCommunicatedTime && <div>
                <span className={'informationSectionText'}>{`LCT: ${lastCommunicatedTime}`}</span>
              </div>}
            </div>
          </div>)
      }
    </>
  )
}
