import React from 'react';
import './DecommissionSummary.css';
import { ButtonContainer } from '../common/buttonContainer/ButtonContainer';
import { Button, ButtonTheme } from '../common/button/Button';
import { EmptySpaceForFixedButtons } from '../common/emptySpaceForFixedButtons/EmptySpaceForFixedButtons';
import { homePageInitialState, useChatReasonContext, useHomePageContext } from '../../context/HomePageContext';

export type DecommissionSummaryProps = {
  deviceType: string;
  smetsDeviceType: string;
  deviceId: string;
  chfDeviceId: string;
  reason: string;
}

export default function DecommissionSummary({
                                              deviceType,
                                              smetsDeviceType,
                                              deviceId,
                                              chfDeviceId,
                                              reason,
                                            }: DecommissionSummaryProps) {
  const { setHomePageState } = useHomePageContext();
  const { setChatReason } = useChatReasonContext();

  function decommissionHandler() {
    if (confirm('Are you sure you want to continue? This can\'t be undone.')) {
      console.log('decommissioned');
    }
  }

  function cancelHandler() {
    setHomePageState({ ...homePageInitialState });
    setChatReason('');
  }

  return <>
    <p className="informationHeading m-b-10">You're ready to decommission this meter</p>
    <p className="m-b-10">This can't be undone. Please double-check these are the correct meter details before confirming the decommission.</p>
    <div id="decommission-summary-container" data-testid="decommission-summary-container">
      <div className="m-b-10">
        <p className="informationHeading">Meter to be decommissioned</p>
        <p>{deviceType}</p>
      </div>
      <div className="m-b-10">
        <p className="informationHeading">{deviceType} GUID</p>
        <p>{deviceId}</p>
      </div>
      <div className="m-b-10">
        <p className="informationHeading">{deviceType} Device Type</p>
        <p>{smetsDeviceType}</p>
      </div>
      <div className="m-b-10">
        <p className="informationHeading">GUID of attached CHF</p>
        <p>{chfDeviceId}</p>
      </div>
      <div className="m-b-10">
        <p className="informationHeading">Reason</p>
        <p>{reason}</p>
      </div>
    </div>
    <ButtonContainer>
      <Button buttonId="decommission-summary-start" buttonText="Decommission" buttonTheme={ButtonTheme.DANGER} isButtonEnabled={true} onClickHandler={decommissionHandler}/>
      <Button buttonId="decommission-summary-cancel" buttonText="Cancel" buttonTheme={ButtonTheme.SECONDARY} onClickHandler={cancelHandler}/>
    </ButtonContainer>
    <EmptySpaceForFixedButtons numberOfButtons={2}/>
  </>
}
