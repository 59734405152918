import React from 'react';
import { XritmResponseData } from '../../types/comms-check';

export const InstantaneousDataSection = ({ xritm }: {xritm: XritmResponseData}) => {
  return <>
    {xritm.data.length
      ? <div className={'informationRow'} data-testid="info-reading-section">
        <div className={'informationSectionText'}>{`Readings for MSN: ${xritm.msn}`}</div>
        {xritm.data.map(({ registerId, reading }) => <div key={registerId} className={'informationSectionText'}>{`${registerId}: ${reading}`}</div>)}
      </div>
      : null}
  </>;
}
